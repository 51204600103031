import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: theme => ({
    display: 'flex',
    columnGap: 2,
    rowGap: 1.25,
    flexWrap: 'wrap',
    alignItems: 'center',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 1,
    bgcolor: theme.palette.common.white,
    py: 1,
    px: 2,
  }),
  title: {
    color: 'text.blackLight',
  },
  group: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 1.25,
  },
  plusIconButton: {
    borderColor: 'border.whiteBlue',
    backgroundColor: 'border.whiteBlue',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  plusIcon: {
    width: '16px',
    height: '16px',
    color: 'text.blue',
  },
};

export default styles;
