/* eslint-disable @typescript-eslint/naming-convention */
import { alpha } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    border: Palette['primary'];
  }
  interface PaletteOptions {
    border: PaletteOptions['primary'];
  }
  interface PaletteColor {
    blue?: string;
    shadow?: string;
    red?: string;
    gray?: string;
    darkGray?: string;
    slateBlueGray?: string;
  }
  interface SimplePaletteColorOptions {
    blue?: string;
    shadow?: string;
    red?: string;
    gray?: string;
    darkGray?: string;
    slateBlueGray?: string;
  }
}

const PRIMARY_COLOR = '#43BDF1';

export default {
  primary: {
    main: PRIMARY_COLOR,
    contrastText: '#fff',
    blue: '#00A1EC',
    red: '#F44F00',
    gray: '#7C8993',
  },
  background: {
    default: '#F2F4F5',
    white: '#FFFFFF',
    black: '#0E1116',
    gray: '#EAEEF0',
    opacityGray: alpha('#EAEEF0', 0.3),
    lilyWhite: '#F9FAFA',
    primaryLight: alpha(PRIMARY_COLOR, 0.04),
    blackLight: alpha('#000000', 0.04),
    lightGreen: '#EBFFEB',
    lightRed: '#FFF8EB',
    lightGray: '#F2F4F5',
    lightBlue: '#B0E2F9',
    frostBlue: '#E9F7FE',
    breezeBlue: '#F5FAFC',
    lightDarkGreen: '#D0F7DE',
    red: '#F44F00',
    green: '#0C9E42',
    mint: '#3ACB70',
    orange: '#E7AD43',
    blue: '#0081CA',
    lightBlueGray: '#eff6f9',
    lightOrange: '#FFE2AD',
  },
  border: {
    main: '#C8D1D8',
    whiteBlue: '#F0FAFE',
    blue: '#00A1EC',
    shadow: '#CBD4D8',
    red: '#F44F00',
    gray: '#F2F4F5',
    lightGray: '#EBEBEB',
    green: '#0C9E42',
    darkGray: '#A8A8A8',
    slateBlueGray: '#9DAAB5',
    orange: '#E7AD43',
  },
  text: {
    primary: '#27353D',
    whiteBlue: '#F0FAFE',
    gray: '#7C8993',
    blackLight: '#55626B',
    green: '#0C9E42',
    red: '#F44F00',
    orange: '#E7AD43',
    lightGray: '#9DAAB5',
    darkRed: '#DA1414',
    blue: '#00A1EC',
    black: '#03202f',
    darkBlue: '#0081CA',
    gunmetalGrey: '#424F58',
    white: '#FFFFFF',
  },
  button: {
    main: '#00A1EC',
    gray: '#7C8993',
  },
};
