import { SxStyles } from 'theme';

const styles: SxStyles = {
  weekListItem: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: {
      mobile: 'column-reverse',
      fablet: 'row',
    },
    alignItems: {
      mobile: 'end',
    },
    columnGap: 2,
    pr: {
      mobile: 2,
      desktop: 13.25,
    },
  },
  weekRangeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: {
      mobile: 'end',
      fablet: 'start',
    },
  },
  label: {
    color: '#7C8993',
    fontSize: 16,
    fontWeight: 600,
  },
  weekDateRange: {
    fontWeight: 700,
    fontSize: 16,
    color: '#55626B',
  },
  weekTotalTimeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  totalTimeLabel: {
    flexShrink: 0,
  },
  weekTotalTime: {
    fontWeight: 700,
    fontSize: 16,
    width: '50px',
    textAlign: 'center',
    color: '#55626B',
  },
  dayListItemContainer: {
    width: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 3,
  },
};

export default styles;
