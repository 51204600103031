import { outlinedInputClasses, autocompleteClasses } from '@mui/material';
import { SxProps } from '@mui/system';

const styles: SxProps = {
  [`&.${autocompleteClasses.root}`]: {
    m: 0,
    [`$.${autocompleteClasses.input}`]: {
      p: 0,
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0',
    p: 0,
    [`& .${outlinedInputClasses.root}`]: {
      p: 0,
    },
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #eee',
  },
};

export default styles;
