import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  resourceCell: {
    position: 'sticky',
    width: 250,
    bgcolor: theme.palette.common.white,
    p: 1,
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  resource: theme => ({
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.primary.blue,
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    overflow: 'hidden',
  }),
  avatar: {
    width: 24,
    height: 24,
  },
  totalCell: {
    position: 'sticky',
    width: 35,
    bgcolor: 'background.lightGray',
    borderRight: `1px solid ${theme.palette.border.main}`,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    textAlign: 'center',
  },
  lastTotalCell: {
    borderRightWidth: 3,
  },
};

export default styles;
