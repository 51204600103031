import { FC, useMemo } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { getHoursAndMinutesStringFromMinutes } from 'utils/dateTime';

import styles from './styles';
import { ITotalInfoProps } from './types';

const TotalInfo: FC<ITotalInfoProps> = props => {
  const { todayTotalMinutes, currentWeekTotalMinutes, pastWeekTotalMinutes } = props;

  const { t } = useTranslation('timeTracker');

  const todayTotalHoursView = useMemo(
    () => getHoursAndMinutesStringFromMinutes(todayTotalMinutes),
    [todayTotalMinutes],
  );

  const currentWeekTotalHoursView = useMemo(
    () => getHoursAndMinutesStringFromMinutes(currentWeekTotalMinutes),
    [currentWeekTotalMinutes],
  );

  const pastWeekTotalMinutesView = getHoursAndMinutesStringFromMinutes(pastWeekTotalMinutes);

  return (
    <Div sx={styles.totalInfoContainer}>
      <Div sx={styles.infoBlock}>
        <Typography variant="heading3" sx={styles.title}>
          {t('totalInfo.today')}:
        </Typography>
        &nbsp;
        <Typography variant="body2" sx={styles.value}>
          {todayTotalHoursView}
        </Typography>
      </Div>
      <Div sx={styles.infoBlock}>
        <Typography variant="heading3" sx={styles.title}>
          {t('totalInfo.currentWeekTotal')}:
        </Typography>
        &nbsp;
        <Typography variant="body2" sx={styles.value}>
          {currentWeekTotalHoursView}
        </Typography>
      </Div>
      <Div sx={styles.infoBlock}>
        <Typography variant="heading3" sx={styles.title}>
          {t('totalInfo.pastWeekTotal')}:
        </Typography>
        &nbsp;
        <Typography variant="body2" sx={styles.value}>
          {pastWeekTotalMinutesView}
        </Typography>
      </Div>
    </Div>
  );
};

export default TotalInfo;
