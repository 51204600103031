import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  row: {
    width: 1,
    display: 'flex',
    flexDirection: {
      mobile: 'column',
      tablet: 'row',
    },
    gap: 2,
    alignItems: {
      mobile: 'start',
      desktop: 'center',
    },
    borderBottom: `1px solid ${theme.palette.border.main}`,
    '&:hover': {
      bgcolor: 'background.lilyWhite',
      transition: 'background-color 0.2s linear',
    },
    '&:hover div[data-actions=entity-action]': {
      visibility: 'visible',
      transition: 'visibility 0.2s linear, opacity 0.2s linear',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    py: {
      mobile: 2,
      desktop: 0.75,
    },
    pl: {
      mobile: 2,
      desktop: 3.5,
    },
    pr: {
      mobile: 2,
      desktop: 4,
    },
    bgcolor: theme.palette.common.white,
  },
  firstGroup: {
    flexGrow: 1,
    overflow: 'hidden',
    maxWidth: 1,
    display: 'flex',
    columnGap: 2,
    rowGap: 1,
    flexDirection: {
      mobile: 'column',
      desktop: 'row',
    },
    alignItems: {
      mobile: 'start',
      desktop: 'center',
    },
  },
  secondInnerGroupOfFirstGroup: {
    flexGrow: 1,
    flexShrink: 0,
    overflow: 'hidden',
    maxWidth: 1,
    display: 'flex',
    columnGap: 2,
    rowGap: 1,
    flexDirection: {
      mobile: 'column',
      desktop: 'row',
    },
    justifyContent: {
      desktop: 'space-between',
    },
  },
  createProjectAndTaskButton: {
    maxWidth: {
      desktop: 220,
    },
  },
  secondGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 2,
    flexDirection: {
      mobile: 'row',
      tablet: 'column',
      desktop: 'row',
    },
    alignSelf: {
      mobile: 'stretch',
      desktop: 'unset',
    },
    alignItems: {
      mobile: 'end',
      desktop: 'center',
    },
  },
  signAndDurationContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 0.25,
  },
  billableProjectSign: {
    width: 24,
    height: 24,
    color: 'text.green',
  },
  hiddenBillableProjectSign: {
    visibility: 'hidden',
  },
  dateWithDuration: {
    m: 0,
    p: 0,
    color: 'text.blackLight',
    width: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  actions: {
    width: 58,
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: {
      mobile: 1.5,
      desktop: 1,
    },
    visibility: {
      mobile: 'visible',
      desktop: 'hidden',
    },
  },
  actionButtonIcon: {
    width: 24,
    height: 24,
  },
  actionButton: {
    width: 24,
    height: 24,
    p: 0,
  },
};

export default styles;
