import { SxStyles } from 'theme';

const styles: SxStyles = {
  container: {
    width: 'fit-content',
  },
  addTagsButton: {
    borderColor: 'border.whiteBlue',
    backgroundColor: 'border.whiteBlue',
    borderRadius: '32px',
  },
  tagsButton: {
    borderColor: 'border.whiteBlue',
    backgroundColor: 'border.whiteBlue',
    borderRadius: '6px',
    border: 'none',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  plusIcon: {
    width: '24px',
    height: '24px',
  },
  tagsPopover: {
    left: '-80px',
  },
};

export default styles;
