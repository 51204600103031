import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { InputBase, Tooltip, Typography } from '@mui/material';
import MUISx from 'mui-sx';

import { useScreen } from 'hooks';

import Div from 'components/common/Div';

import styles from './styles';

interface IAutoSizeInputProps {
  disabled: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const MIN_WIDTH_DIFFERENCE = 2;
const OPEN_DELAY = 700;

const AutoSizeInput = (props: IAutoSizeInputProps) => {
  const { disabled, value, onChange, onSubmit } = props;

  const { moreThanDesktop } = useScreen();
  const inputReference = useRef<HTMLDivElement>();

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isTextEllipsised, setIsTextEllipsised] = useState<boolean>(false);

  const tooltipTitle = moreThanDesktop && isTextEllipsised && value;

  const handleReferenceAdd = (element: HTMLInputElement | null) => {
    if (element) {
      inputReference.current = element;
    }
  };

  const handleFocus = () => {
    if (disabled) return;

    setIsFocused(true);
    inputReference.current?.focus();
  };

  const handleBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false);
    onSubmit(event);
  };

  useEffect(() => {
    if (inputReference.current) {
      const { scrollWidth, offsetWidth } = inputReference.current;
      const isEllipsised = scrollWidth - offsetWidth > MIN_WIDTH_DIFFERENCE;

      setIsTextEllipsised(isEllipsised);
    }
  }, [value, inputReference?.current?.offsetWidth]);

  return (
    <Div sx={styles.inputContainer} onFocus={handleFocus}>
      <Tooltip
        arrow
        placement="top-start"
        enterDelay={OPEN_DELAY}
        enterNextDelay={OPEN_DELAY}
        title={tooltipTitle}
        sx={styles.tooltipContainer}
        slotProps={{
          tooltip: { sx: styles.tooltip },
        }}
      >
        <InputBase
          multiline={!moreThanDesktop}
          value={value}
          inputRef={handleReferenceAdd}
          sx={MUISx(styles.inputField, { condition: isFocused, sx: styles.visibleInputField })}
          inputProps={{
            sx: MUISx(styles.baseInput, styles.input),
          }}
          onChange={onChange}
          onBlur={handleBlur}
        />
      </Tooltip>
      <Typography
        component="div"
        sx={MUISx(
          styles.baseInput,
          styles.inputMirror,
          {
            condition: isFocused,
            sx: styles.hiddenInputMirror,
          },
          {
            condition: disabled,
            sx: styles.disabled,
          },
        )}
      >
        {value}
      </Typography>
    </Div>
  );
};

export default AutoSizeInput;
