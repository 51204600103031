import { SxStyles } from 'theme';

const styles: SxStyles = {
  dayListItem: theme => ({
    width: 1,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 2,
    overflow: 'hidden',
  }),
  group: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingY: 1.5,
    pl: 2,
    pr: {
      mobile: 2,
      desktop: 13.25,
    },
    backgroundColor: 'background.gray',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },
  headerTitle: {
    color: 'text.blackLight',
  },
  totalEntriesHours: {
    width: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  lockedPeriodContainer: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: '#EFE4CA',
    border: '1px solid #E9BD6A',
    borderRadius: '6px',
    color: '#55626B',
    padding: '1em',
    gap: 2,
  },
};

export default styles;
