import { format } from 'date-fns';

import { formatToDateWithFixedTimeZone, MONTH_DAY_FORMAT } from 'utils/dateTime';

export const weekRangeToString = (weekRange: [string, string]) => {
  const [start, end] = weekRange;
  const startDate = formatToDateWithFixedTimeZone(start);
  const endDate = formatToDateWithFixedTimeZone(end);

  return `${format(startDate, MONTH_DAY_FORMAT)} - ${format(endDate, MONTH_DAY_FORMAT)}`;
};

export const isLockedPeriod = (dateString: string, editLockDateString: string): boolean => {
  const date = formatToDateWithFixedTimeZone(dateString);
  const editLockDate = formatToDateWithFixedTimeZone(editLockDateString);

  return date <= editLockDate ? true : false;
};
