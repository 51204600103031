import { SxStyles } from 'theme';

const styles: SxStyles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 3,
    position: 'relative',
  },
  loadMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default styles;
