import { List, ListItem } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { formatMinutesToString, formatToDateWithFixedTimeZone } from 'utils/dateTime';

import DayListItem from './components/DayListItem';
import { isLockedPeriod, weekRangeToString } from './service';
import styles from './styles';
import { IWeekListItemProps } from './types';

const WeekListItem = (props: IWeekListItemProps) => {
  const {
    resourceId,
    editLockDate,
    currentWeekNumber,
    weekEntries: { weekNumber, weekRange, totalWeekMinutes, entriesByDate },
    onTimeTrackerSettingsCopy,
    isFirstWeekBeforeLock,
  } = props;

  const { t } = useTranslation('timeTracker');

  const translate = (key: string, options: Record<string, number>) => t(`trackedTimeEntriesGroupList.${key}`, options);

  const weekLabelKey = weekNumber === currentWeekNumber ? 'currentWeek' : 'week';

  const sortedTrackedTimeEntriesByDateKeys = _.orderBy(Object.keys(entriesByDate), formatToDateWithFixedTimeZone, [
    'desc',
  ]);

  let isFirstDayBeforeLockFound = false;

  return (
    <ListItem sx={styles.weekListItem}>
      <Div sx={styles.header}>
        <Div sx={styles.weekRangeContainer}>
          <Div sx={styles.label}>{translate(weekLabelKey, { weekNumber })}:&nbsp;</Div>
          <Div sx={styles.weekDateRange}>{weekRangeToString(weekRange)}</Div>
        </Div>
        <Div sx={styles.weekTotalTimeContainer}>
          <Div sx={MUISx(styles.label, styles.totalTimeLabel)}>{translate('totalWeek', { weekNumber })}:&nbsp;</Div>
          <Div sx={styles.weekTotalTime}>{formatMinutesToString(totalWeekMinutes)}</Div>
        </Div>
      </Div>
      <List sx={styles.dayListItemContainer}>
        {sortedTrackedTimeEntriesByDateKeys.map(dateKey => {
          const dayEntries = entriesByDate[dateKey];
          const dayListKey = `${dateKey}:${dayEntries.totalDayMinutes}`;
          const isEditLocked = isLockedPeriod(dateKey, editLockDate);
          const isLockPeriodLabelRenderRequired = isFirstWeekBeforeLock
            ? isEditLocked && !isFirstDayBeforeLockFound
            : false;
          if (isLockPeriodLabelRenderRequired) {
            isFirstDayBeforeLockFound = true;
          }

          return (
            <DayListItem
              key={dayListKey}
              resourceId={resourceId}
              date={dateKey}
              dayEntries={dayEntries}
              onTimeTrackerSettingsCopy={onTimeTrackerSettingsCopy}
              isLockPeriodLabelRenderRequired={isLockPeriodLabelRenderRequired}
            />
          );
        })}
      </List>
    </ListItem>
  );
};

export default WeekListItem;
