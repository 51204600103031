import { Lock } from '@mui/icons-material';
import { List, ListItem, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import Div from 'components/common/Div';

import { ResourceTrackedTimeDayEntries } from 'domain/resource/trackedTimeEntry/types';

import { formatDateTime, getHoursAndMinutesStringFromMinutes } from 'utils/dateTime';

import TimeEntryItem from './components/TimeEntryItem';
import styles from './styles';

interface IDayListItemsProps {
  resourceId: number;
  date: string;
  dayEntries: ResourceTrackedTimeDayEntries;
  onTimeTrackerSettingsCopy: (formData) => void;
  isLockPeriodLabelRenderRequired: boolean;
}

const DayListItem = (props: IDayListItemsProps) => {
  const { resourceId, dayEntries, date, onTimeTrackerSettingsCopy, isLockPeriodLabelRenderRequired } = props;

  const { t } = useTranslation('timeTracker');

  const groupHeaderDate = formatDateTime(date);
  const { totalDayMinutes, entries } = dayEntries;
  const sortedResourceTrackedEntries = _.orderBy(entries, ['id'], ['desc']);

  return (
    <>
      {isLockPeriodLabelRenderRequired && (
        <Div sx={styles.lockedPeriodContainer}>
          <Lock />
          <Div>{t('trackedTimeEntriesGroupList.lockedPeriod')}</Div>
        </Div>
      )}
      <ListItem sx={styles.dayListItem}>
        <Div sx={styles.header}>
          <Typography sx={styles.headerTitle} variant="body1">
            {groupHeaderDate}
          </Typography>
          <Typography sx={MUISx(styles.headerTitle, styles.totalEntriesHours)} variant="h4" component="p">
            {getHoursAndMinutesStringFromMinutes(totalDayMinutes)}
          </Typography>
        </Div>
        <List sx={styles.group}>
          {sortedResourceTrackedEntries.map(timeEntry => (
            <TimeEntryItem
              key={timeEntry.id}
              timeEntry={timeEntry}
              onTimeTrackerSettingsCopy={onTimeTrackerSettingsCopy}
              resourceId={resourceId}
            />
          ))}
        </List>
      </ListItem>
    </>
  );
};

export default DayListItem;
