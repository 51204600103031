import { FC, useState, useContext } from 'react';

import { AttachMoneyOutlined } from '@mui/icons-material';
import { Button, IconButton, ListItem, Popover, Typography } from '@mui/material';
import _ from 'lodash';
import MUISx from 'mui-sx';
import { useTranslation } from 'react-i18next';

import CreateProjectAndTaskButton from 'components/common/CreateProjectAndTaskButton';
import CreateTagsButton from 'components/common/CreateTagsButton';
import DeleteTimeEntryDialog from 'components/common/DeleteTimeEntryDialog';
import Div from 'components/common/Div';
import Icon from 'components/common/Icon';

import { ModalContext } from 'contexts/ModalContext';

import { useUpdateResourceTrackedTimeEntryMutation } from 'domain/resource/trackedTimeEntry/apiSlice';
import { ITag } from 'domain/tag/types';
import { TrackedTimeEntrySource } from 'domain/timeTracker/trackedTimeEntry/enums';

import { useNotifications } from 'hooks/useNotifications';

import { IDuration } from 'types/dates';
import { ITimeTrackerTrackableState } from 'types/timeTrackerTrackable';

import {
  formatToDateWithFixedTimeZone,
  formatToUTCString,
  getHoursAndMinutesStringFromMinutes,
  getMinutesFromHours,
} from 'utils/dateTime';
import { generateBackendErrorMessages } from 'utils/error';

import AutoSizeInput from './components/AutoSizeInput';
import DatePickerWithDuration from './components/DatePickerWithDuration';
import styles from './styles';
import { ITimeEntryItemProps } from './types';

const TimeEntryItem: FC<ITimeEntryItemProps> = props => {
  const { timeEntry, resourceId, onTimeTrackerSettingsCopy } = props;

  const [updateResourceTrackedEntity, { isLoading: isUpdateResourceTrackedEntityLoading }] =
    useUpdateResourceTrackedTimeEntryMutation();

  const { t } = useTranslation('timeTracker');

  const { showErrorNotification } = useNotifications();
  const { openModal } = useContext(ModalContext);

  const [currentTimeTrackerTrackable, setCurrentTimeTrackerTrackable] = useState<ITimeTrackerTrackableState>({
    trackable: {
      id: timeEntry.trackableId,
      name: timeEntry.trackableName,
      billable: timeEntry.billable,
      type: timeEntry.trackableType,
      tasks: [timeEntry.task],
    },
    task: timeEntry.task,
  });

  const [currentTimeTrackerTags, setCurrentTimeTrackerTags] = useState<Array<ITag>>(timeEntry.tags);
  const [description, setDescription] = useState<string>(timeEntry.description);

  const [hours, minutes] = getHoursAndMinutesStringFromMinutes(timeEntry.duration)?.split(':');

  const [duration, setDuration] = useState<IDuration>({
    hours: String(Number.parseInt(hours)),
    minutes: String(Number.parseInt(minutes)),
  });

  const [date, setDate] = useState<Date>(formatToDateWithFixedTimeZone(timeEntry.date));
  const [datePickerWithDurationElement, setDatePickerWithDurationElement] = useState<HTMLButtonElement | null>(null);

  const previousFormData = {
    description: timeEntry.description,
    duration: timeEntry.duration,
    taskId: timeEntry.task?.id ?? null,
    trackableId: timeEntry.trackableId,
    trackableType: timeEntry.trackableType,
    tagIds: timeEntry.tags.map(({ id }) => id),
    date: formatToUTCString(formatToDateWithFixedTimeZone(timeEntry.date)),
  };

  const isDatePickerWithDurationOpen = Boolean(datePickerWithDurationElement);
  const isBillableProject = currentTimeTrackerTrackable.trackable.billable;
  const isDisabled =
    timeEntry.source === TrackedTimeEntrySource.importedFromToggl || isUpdateResourceTrackedEntityLoading;

  const datePickerWidthDurationId = isDatePickerWithDurationOpen ? 'trackable-date-picker' : undefined;

  const handleDatePickerWithDurationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDatePickerWithDurationElement(event.currentTarget);
  };

  const handleDatePickerWithDurationClose = async () => {
    await handleResourceTrackedEntityUpdate();
    setDatePickerWithDurationElement(null);
  };

  const handleResourceTrackedEntityUpdateError = error => {
    const errors = generateBackendErrorMessages(error);
    setDescription(timeEntry.description);
    const errorMessage = errors.join('\r\n');
    showErrorNotification(errorMessage);
  };

  const handleResourceTrackedEntityUpdate = async () => {
    const durationMinutes = getMinutesFromHours(Number(duration?.hours)) + Number(duration?.minutes);

    const formData = {
      description,
      duration: durationMinutes,
      taskId: currentTimeTrackerTrackable?.task?.id,
      trackableId: currentTimeTrackerTrackable?.trackable?.id,
      trackableType: currentTimeTrackerTrackable?.trackable?.type,
      tagIds: currentTimeTrackerTags?.map(({ id }) => id),
      date: formatToUTCString(date),
    };

    if (_.isEqual(formData, previousFormData)) return;

    const parameters = {
      id: timeEntry.id,
      resourceId,
      formData,
    };

    try {
      const resourceTrackedEntity = await updateResourceTrackedEntity(parameters).unwrap();

      setDescription(resourceTrackedEntity.description);
    } catch (error) {
      handleResourceTrackedEntityUpdateError(error);
    }
  };

  const handleTimeEntryProjectUpdate = async (trackable?: ITimeTrackerTrackableState) => {
    const durationMinutes = getMinutesFromHours(Number(duration?.hours)) + Number(duration?.minutes);

    const formData = {
      description,
      duration: durationMinutes,
      taskId: trackable?.task?.id,
      trackableId: trackable?.trackable?.id,
      trackableType: trackable?.trackable?.type,
      tagIds: currentTimeTrackerTags?.map(({ id }) => id),
      date: formatToUTCString(date ?? new Date()),
    };

    if (!_.isEqual(formData, previousFormData)) {
      try {
        await updateResourceTrackedEntity({ id: timeEntry.id, resourceId, formData }).unwrap();
      } catch (error) {
        const errors = generateBackendErrorMessages(error);
        for (const message of errors) {
          showErrorNotification(message);
        }
      }
    }
  };

  const handleDeleteResourceTrackedEntity = () => {
    openModal({
      title: t('deleteTimeEntryDialog.title'),
      content: <DeleteTimeEntryDialog id={timeEntry.id} resourceId={resourceId} />,
    });
  };

  const handleCopyResourceTrackedEntity = () => {
    const durationMinutes = getMinutesFromHours(Number(duration?.hours)) + Number(duration?.minutes);
    const formData = {
      description,
      date: new Date(),
      duration: durationMinutes,
      trackable: currentTimeTrackerTrackable,
      tags: currentTimeTrackerTags,
    };

    onTimeTrackerSettingsCopy?.(formData);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDescription(value);
  };

  return (
    <ListItem sx={styles.row}>
      <Div sx={styles.firstGroup}>
        <AutoSizeInput
          disabled={isDisabled}
          value={description}
          onChange={handleDescriptionChange}
          onSubmit={handleResourceTrackedEntityUpdate}
        />
        <Div sx={styles.secondInnerGroupOfFirstGroup}>
          <CreateProjectAndTaskButton
            isAreaDisabled={isDisabled}
            sx={styles.createProjectAndTaskButton}
            currentTimeTrackerTrackable={currentTimeTrackerTrackable}
            setCurrentTimeTrackerTrackable={setCurrentTimeTrackerTrackable}
            onCurrentTimeTrackerTrackableChange={handleTimeEntryProjectUpdate}
          />
          <CreateTagsButton
            currentTags={currentTimeTrackerTags}
            setCurrentTags={setCurrentTimeTrackerTags}
            isDisabled={isDisabled}
            onBlur={handleResourceTrackedEntityUpdate}
          />
        </Div>
      </Div>
      <Div sx={styles.secondGroup}>
        <Div sx={styles.signAndDurationContainer}>
          <AttachMoneyOutlined
            sx={MUISx(styles.billableProjectSign, {
              condition: !isBillableProject,
              sx: styles.hiddenBillableProjectSign,
            })}
          />
          <Button
            sx={styles.dateWithDuration}
            aria-describedby={datePickerWidthDurationId}
            variant="text"
            onClick={handleDatePickerWithDurationClick}
            disabled={isDisabled}
          >
            <Typography variant="body1">
              {hours}:{minutes}
            </Typography>
          </Button>
          <Popover
            id={datePickerWidthDurationId}
            open={isDatePickerWithDurationOpen}
            anchorEl={datePickerWithDurationElement}
            onClose={handleDatePickerWithDurationClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <DatePickerWithDuration
              duration={duration}
              setDuration={setDuration}
              date={date}
              setDate={setDate}
              isDisabled={isDisabled}
            />
          </Popover>
        </Div>
        <Div data-actions="entity-action" sx={styles.actions}>
          <IconButton onClick={handleCopyResourceTrackedEntity} sx={styles.actionButton}>
            <Icon sx={styles.actionButtonIcon} name="copy" />
          </IconButton>
          <IconButton onClick={handleDeleteResourceTrackedEntity} sx={styles.actionButton} disabled={isDisabled}>
            <Icon sx={styles.actionButtonIcon} name="trash" />
          </IconButton>
        </Div>
      </Div>
    </ListItem>
  );
};

export default TimeEntryItem;
