import { SxStyles } from 'theme';

const styles: SxStyles = {
  totalInfoContainer: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    justifyContent: {
      mobile: 'space-between',
      desktop: 'end',
    },
    columnGap: 1.5,
  },
  infoBlock: {
    display: 'flex',
  },
  title: {
    color: 'text.gray',
  },
  value: theme => ({
    fontSize: {
      mobile: theme.typography.pxToRem(16),
      tablet: theme.typography.pxToRem(18),
    },
  }),
};

export default styles;
