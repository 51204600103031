import { inputBaseClasses } from '@mui/material';

import theme, { SxStyles } from 'theme';

const styles: SxStyles = {
  inputContainer: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 1,
  },
  tooltipContainer: {
    maxWidth: 'inherit',
  },
  tooltip: {
    whiteSpace: 'pre-wrap',
    maxWidth: 800,
    top: 8,
  },
  inputField: {
    width: 1,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: 0,
    lineHeight: theme.typography.pxToRem(24),
    alignItems: {
      mobile: 'start',
      desktop: 'center',
    },
    [`&.${inputBaseClasses.root}`]: {
      p: 0,
    },
  },
  visibleInputField: {
    opacity: 1,
  },
  baseInput: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  input: {
    border: 'none',
    outline: 'none',
    maxWidth: 1,
    p: 0,
  },
  inputMirror: {
    whiteSpace: {
      mobile: 'pre-wrap',
      desktop: 'pre',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hiddenInputMirror: {
    opacity: 0,
    pointerEvents: 'none',
  },
  disabled: {
    opacity: 0.42,
  },
};

export default styles;
