import { SxStyles } from 'theme';

const styles: SxStyles = {
  headerContainer: theme => ({
    position: {
      desktop: 'sticky',
    },
    top: -64,
    zIndex: theme.zIndex.appBar,
    backgroundColor: 'background.default',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 3,
  }),
  header: theme => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    borderBottom: `1px solid ${theme.palette.border.main}`,
    pb: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      lineHeight: theme.typography.pxToRem(24),
      bottom: -33,
      height: 32,
      background: `linear-gradient(to top, transparent 0%, ${theme.palette.background.default} 97%)`,
      pointerEvents: 'none',
    },
  }),
};

export default styles;
