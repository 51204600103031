import { FC, useState } from 'react';

import { Button, Popover, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import Div from 'components/common/Div';
import Icon from 'components/common/Icon';
import TrackedTimeEntryTagsChip from 'components/common/TrackedTimeEntry/components/TrackedTimeEntryTagsChip';

import { useGetTagsQuery } from 'domain/tag/apiSlice';
import { getTagsNames } from 'domain/tag/presenter';
import { ITag } from 'domain/tag/types';

import { DEFAULT_DEBOUNCE_DELAY_MS } from 'utils/constants';
import { getSortParameter } from 'utils/runsack';

import { ConditionState } from '../SmartFilter/enums';

import TagsAutocomplete from './components';
import styles from './styles';
import { ICreateTagsButtonProps } from './types';

const CreateTagsButton: FC<ICreateTagsButtonProps> = props => {
  const { currentTags, setCurrentTags, isDisabled, onBlur } = props;

  const { t } = useTranslation(['common', 'timeTracker']);

  const [tagName, setTagName] = useState<string>('');

  const [debouncedTagName] = useDebounce(tagName, DEFAULT_DEBOUNCE_DELAY_MS);
  const tagsNames = getTagsNames(currentTags);

  const {
    data: tagsData,
    isFetching: isTagsDataFetching,
    isError: isTagsDataLoadingError,
  } = useGetTagsQuery({
    nameCont: debouncedTagName,
    sort: getSortParameter('name', 'asc'),
    stateEq: ConditionState.active,
  });

  const [createTagsElement, setCreateTagsElement] = useState<HTMLButtonElement | null>(null);

  const handleCreateTagsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCreateTagsElement(event.currentTarget);
  };

  const handleCreateTagsClose = () => {
    setCreateTagsElement(null);
  };

  const createTagsOpen = Boolean(createTagsElement);
  const createTagsButtonId = 'create-tags';

  const handleCurrentTagsChange = (tags: Array<ITag>) => {
    if (_.isNil(tags)) {
      setCurrentTags([]);
      return;
    }
    setCurrentTags(tags);
  };

  const hasTimeTrackerTags = !_.isEmpty(currentTags);

  return (
    <Div sx={styles.container} onBlur={onBlur}>
      {hasTimeTrackerTags ? (
        <Button
          variant="text"
          aria-describedby={createTagsButtonId}
          onClick={handleCreateTagsClick}
          type="button"
          sx={styles.tagsButton}
          disabled={isDisabled}
          size="small"
        >
          <TrackedTimeEntryTagsChip tagsNames={tagsNames} />
        </Button>
      ) : (
        <Button
          variant="outlined"
          startIcon={<Icon sx={styles.plusIcon} name="plusBorderless" />}
          aria-describedby={createTagsButtonId}
          onClick={handleCreateTagsClick}
          type="button"
          sx={styles.addTagsButton}
          disabled={isDisabled}
        >
          <Typography variant="h5">{t('timeTracker:createTagsButton.button')}</Typography>
        </Button>
      )}

      <Popover
        id={createTagsButtonId}
        open={createTagsOpen}
        anchorEl={createTagsElement}
        onClose={handleCreateTagsClose}
        sx={styles.tagsPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <TagsAutocomplete
          options={tagsData?.tags ?? []}
          currentValue={currentTags}
          isFetching={isTagsDataFetching}
          isError={isTagsDataLoadingError}
          onCurrentValueChange={handleCurrentTagsChange}
          setName={setTagName}
          name={tagName}
          placeholder={t('timeTracker:createTagsButton.autocomplete.placeholder')}
        />
      </Popover>
    </Div>
  );
};

export default CreateTagsButton;
