import { SxStyles } from 'theme';

const styles: SxStyles = {
  weekListItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 3,
  },
};

export default styles;
