import { FC } from 'react';

import { List } from '@mui/material';

import WeekListItem from './components/WeekListItem';
import { anyWeekEntryBeforeLock } from './service';
import styles from './styles';
import { ITimeTrackerListProps } from './types';

const TimeTrackerList: FC<ITimeTrackerListProps> = props => {
  const {
    resourceId,
    editLockDate,
    currentWeekNumber,
    timeEntriesGroupedByDate: { sortedWeekKeys, entriesByWeek },
    onTimeTrackerSettingsCopy,
  } = props;

  let isFirstWeekBeforeLockFound = false;

  return (
    <List sx={styles.weekListItemContainer}>
      {sortedWeekKeys.map(weekKey => {
        const weekEntries = entriesByWeek[weekKey];
        const weekListKey = `${weekKey}:${weekEntries.totalWeekMinutes}`;
        const isFirstWeekBeforeLock = isFirstWeekBeforeLockFound
          ? false
          : anyWeekEntryBeforeLock(weekEntries.weekRange, editLockDate);
        if (isFirstWeekBeforeLock) {
          isFirstWeekBeforeLockFound = true;
        }

        return (
          <WeekListItem
            key={weekListKey}
            resourceId={resourceId}
            editLockDate={editLockDate}
            weekEntries={weekEntries}
            currentWeekNumber={currentWeekNumber}
            onTimeTrackerSettingsCopy={onTimeTrackerSettingsCopy}
            isFirstWeekBeforeLock={isFirstWeekBeforeLock}
          />
        );
      })}
    </List>
  );
};

export default TimeTrackerList;
